<template>
  <div class="income">
    <en-table-layout :tableData="tableData.data" @sort-change="handleSortChange">
      <template slot="toolbar">
        <div class="buttonbc">
          <el-button @click="() => { $router.go(-1); }" size="small" class="back_btn">
            <img src="@/assets/back.png" alt="">
          </el-button>
        </div>
        <br />
        <el-form-item label="所属分类" class="col-auto">
          <el-select style="width: 110px" v-model="params.classification" placeholder="请选择" clearable>
            <el-option v-for="(item, index) in options" :key="index" :label="item" :value="item" />
          </el-select>
        </el-form-item>
        <el-form-item label="税率" class="col-auto">
          <el-select style="width: 110px" v-model="params.tax_rate" placeholder="请选择" clearable>
            <el-option label="全部" value="" />
            <el-option v-for="(item, index) in taxrateOptions" :key="index" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input style="width: 290px;" size="small" placeholder="请输入关键字进行搜索" v-model.trim="params.keyWord" clearable>
            <el-select v-model="key_word" slot="prepend" style="width: 100px;">
              <el-option label="货物名称" value="name"></el-option>
              <el-option label="货物编号" value="no"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item class="col-auto">
          <el-button native-type="submit" @click.stop.prevent="searchEvent" size="small" type="primary">搜索</el-button>
        </el-form-item>
        <el-form-item>
          <el-button @click="handleEdit()" size="small" type="primary">添加明细</el-button>
          <el-button @click="handleImport" size="small" type="primary">导入明细</el-button>
          <el-button @click="handleExport" :loading="importLoading" size="small" type="primary">导出全部</el-button>
          <el-button @click="handleHistory" size="small" type="primary">导入/导出记录</el-button>
        </el-form-item>
      </template>
      <template slot="table-columns">
        <el-table-column prop="no" show-overflow-tooltip label="货物编号" fixed="left" width="160" />
        <el-table-column prop="name" show-overflow-tooltip label="货物名称" fixed="left" />
        <el-table-column prop="tax_rate" show-overflow-tooltip label="所属分类" width="180" fixed="left">
          <template slot-scope="{row}">{{ row.classification }}</template>
        </el-table-column>
        <el-table-column prop="tax_rate" show-overflow-tooltip label="税率(%)" width="100">
          <template slot-scope="{row}">{{ row.tax_rate }}</template>
        </el-table-column>
        <el-table-column prop="type" show-overflow-tooltip label="型号" width="150" />
        <el-table-column prop="unit" show-overflow-tooltip label="单位" width="80" />
        <el-table-column prop="price" show-overflow-tooltip label="单价" width="100" sortable="custom" />
        <el-table-column prop="left_num" show-overflow-tooltip label="可开数量" width="110" sortable="custom" />
        <!--操作-->
        <el-table-column label="操作" width="150" fixed="right">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="handleEdit(scope.row)">
              编辑
            </el-button>
            <el-button size="mini" type="danger" @click="handleRemove(scope.row)">删除
            </el-button>
          </template>
        </el-table-column>
      </template>
      <el-pagination v-if="tableData" slot="pagination" @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange" :current-page="tableData.page_no" :page-size="tableData.page_size"
        :page-sizes="MixinPageSizes" :layout="MixinTableLayout" background
        :total="tableData.data_total"></el-pagination>
    </en-table-layout>
    <!--编辑、添加操作 dialog-->
    <x-dialog :proxy="detailedDialog">
      <el-form style="padding:20px" :model="goodsAuditForm" :rules="goodsAuditFormRules" ref="goodsAuditForm"
        label-width="100px">
        <el-form-item label="货物名称" prop="name">
          <el-input v-model.trim="goodsAuditForm.name"></el-input>
        </el-form-item>
        <el-form-item label="所属分类" prop="classification">
          <el-input v-model.trim="goodsAuditForm.classification"></el-input>
        </el-form-item>
        <el-form-item label="税率" prop="tax_rate">
          <el-select style="width: 110px" v-model="goodsAuditForm.tax_rate" placeholder="请选择">
            <el-option v-for="(item, index) in taxrateOptions" :key="index" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="货物型号" prop="type">
          <el-input v-model.trim="goodsAuditForm.type" maxlength="50"></el-input>
        </el-form-item>
        <el-form-item label="单位" prop="unit">
          <el-input v-model.trim="goodsAuditForm.unit" maxlength="10"></el-input>
        </el-form-item>
        <el-form-item label="单价" prop="price">
          <el-input v-model.trim="goodsAuditForm.price" maxlength="10"></el-input>
        </el-form-item>
        <el-form-item label="可开数量" prop="left_num">
          <el-input @input="inputfilter(goodsAuditForm.left_num, 'left_num')" v-model.trim="goodsAuditForm.left_num"
            maxlength="10"></el-input>
        </el-form-item>
      </el-form>
    </x-dialog>
    <!-- 导入操作 -->
    <x-dialog :proxy="addCardPasswordDialog">
      <div class="mt-3">
        <h3 style="color: #333">第一步：填写导入数据信息</h3>
        <div class="tip">
          <p>1、请按照数据模板的格式准备导入数据，模板中的表头名称不可更改，表头行不能删除。</p>
          <p>2、表格中所有字段均为必填项。</p>
          <p>3、表格税率字段中仅允许填写0、正整数，或“免税”的文字内容。</p>
        </div>
        <el-button size="medium" type="text" @click="downTemplate">下载模板</el-button>
      </div>
      <div class="mt-3" style="margin-bottom: 20px;">
        <h3 style="color: #333">第二步：上传填好的信息表</h3>
        <div class="tip">
          文件后缀名必须为 .xls 或 .xlsx（即 Excel 格式），最多支持导入1万条数据。
        </div>
        <upload-excel-component defaultSize :on-success="excelSuccess" type="text" size="medium"
          @upFileName="e => { this.tooltipContent = e }" />
        <el-tooltip v-if="tooltipContent" class="item" effect="dark" :content="tooltipContent" placement="top-start">
          <el-button class="add-camilo-file">{{ tooltipContent }}<i @click="clearnUploadFiles" style="margin-left:6px"
              class="el-icon-circle-close"></i></el-button>
        </el-tooltip>
        <br />
        <span v-if="error_msg" style="color: red;">请上传文件</span>
      </div>


    </x-dialog>
    <!-- 导入操作提示 -->
    <x-dialog :proxy="postponeTip">
      <div class="tipContent" style="padding: 20px;">
        <div class="tipContent-text">{{ postponeTipText }}</div>
        <div class="tipContent-button">
          <el-button type="primary" v-if="uploadErrData.length" size="small" @click="downloadErrData">下载失败数据</el-button>
        </div>
      </div>
    </x-dialog>
    <exportHistory ref="exportHistory" :parentId="parentId"></exportHistory>
  </div>
</template>
<script>
import UploadExcelComponent from "@/components/UploadExcel";
import { couponExport_down_to_excel } from '@/utils/vendor/Export2Excel'
import EnTableLayout from '@/../ui-components/TableLayout/src/main';
import XDialog from "@/components/x-dialog/x-dialog";
import { $xDialog } from "@/components/x-dialog/dialog.proxy";
import { handleDownload, selfDefinedTemplate } from "@/utils";
import exportHistory from '../components/exportHistory';
import * as API_settingCustomer from '@/api/settingCustomer';
import { RegExp } from '@/../ui-utils';
export default {
  name: 'income',
  components: { EnTableLayout, XDialog, exportHistory, UploadExcelComponent, },
  data () {
    return {
      exportHistoryVisible: false,
      uploadErrData: [],
      error_msg: false,
      tHeaders: ["货物名称", "货物分类", "税率(%)", "型号", "单位", "单价", "可开数量"],
      filterVals: ["name", "classification", "tax_rate", "type", "unit", "price", "left_num"],
      tooltipContent: "",//上传文件名称
      importData: [],//上传的文件集合
      importLoading: false,//导出全部
      cancelActivatedAllLoading: false,
      parentId: '',
      isName: '',//货物名称是否重复
      //分类
      options: [],
      //税率
      taxrateOptions: [
        { value: '13', label: '13%' },
        { value: '9', label: '9%' },
        { value: '6', label: '6%' },
        { value: '0', label: '0%' },
        { value: '免税', label: '免税' },
      ],
      key_word: 'name',
      // 列表参数
      params: {
        page_no: 1,
        page_size: 20,
        tax_rate: '',
        classification: '',
        es_enterprise_collecting_account_id: '',
        keyWord: "",
      },
      // 列表数据
      tableData: {
        data: []
      },
      //添加明细
      detailedDialog: $xDialog.create({
        wrapperClass: 'show-withdrawal-info',
        width: '50vw',
        displayFooterExtras: true,
        beforeConfirm: this.submitGoodsAuditForm
      }),
      goodsAuditForm: {
        es_enterprise_collecting_account_id: '',
        name: '',
        classification: '',
        tax_rate: '13',
        type: '',
        unit: '',//单位
        price: '',//单价
        left_num: '',//可开数量
      },
      goodsAuditFormRules: {
        name: [
          { required: true, message: '请填写货物名称', trigger: 'blur' },
          {
            validator: async (rule, value, callback) => {
              if (this.goodsAuditForm.id && this.isName != value) {//编辑
                const res = await API_settingCustomer.getCustomerAccountIncomeDetailByName(this.parentId, value)
                if (res) callback(new Error('货物名称已存在，请重新填写'))
                else callback()
              } else if (!this.goodsAuditForm.id) {//添加
                const res = await API_settingCustomer.getCustomerAccountIncomeDetailByName(this.parentId, value)
                if (res) callback(new Error('货物名称已存在，请重新填写'))
                else callback()
              } else {
                callback()
              }
            }, trigger: 'blur'
          }
        ],
        classification: [
          { required: true, message: '请填写所属分类', trigger: 'blur' }
        ],
        tax_rate: [
          { required: true, message: '请选择税率', trigger: 'change' }
        ],
        type: [
          { required: true, message: '请填写货物型号', trigger: 'blur' }
        ],
        unit: [
          { required: true, message: '请填写货物单位', trigger: 'blur' }
        ],
        price: [
          { required: true, message: '请填写货物单价', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (!RegExp.money.test(value) || value <= 0) {
                callback(new Error("请输入正确的单价"));
              } else {
                callback()
              }
            }, trigger: 'blur'
          }
        ],
        left_num: [
          { required: true, message: '请填写可开数量', trigger: 'blur' }
        ],
      },
      //失败数据下载
      postponeTip: $xDialog.create({
        title: "提示",
        width: "400px",
        disableCancel: true,
        disableConfirm: true,
        wrapperClass: "addCardPasswordDialog",
      }),
      addCardPasswordDialog: $xDialog.create({
        title: "导入操作",
        width: "600px",
        wrapperClass: 'import-member-dialog',
        beforeConfirm: this.uploadData
      }),
      //上传文件名称
      tooltipContent: '',
      //上传失败内容
      postponeTipText: "",
    }
  },
  created () {
    this.parentId = this.$route.params.id;
    this.params.es_enterprise_collecting_account_id = this.$route.params.id;
    this.goodsAuditForm.es_enterprise_collecting_account_id = this.$route.params.id;
    this.GET_classification()
    this.GET_GoodsAuditList()
  },
  methods: {
    taxRatefilter (tax_rate) {
      switch (tax_rate) {
        case '13':
          return '13%';
        case '9':
          return '9%';
        case '6':
          return '6%';
        case '0':
          return '0%';
        case '免税':
          return '免税';
      }
    },
    /**排序事件 */
    handleSortChange ({
      prop,
      order
    }) {
      if (order) {
        this.params.sort = `${prop}_${order.includes("asc") ? "asc" : "desc"}`;
      } else {
        this.params.sort = "";
      }
      this.GET_GoodsAuditList();
    },
    /** 搜索事件触发 */
    searchEvent (data) {
      this.params.page_no = 1;
      this.GET_GoodsAuditList();
    },
    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_size = size;
      this.GET_GoodsAuditList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.GET_GoodsAuditList();
    },

    /** 获取分类数据 */
    async GET_classification () {
      this.options = await API_settingCustomer.getCustomerAccountIncomeClassification(this.parentId)
    },

    /** 获取列表数据 */
    GET_GoodsAuditList () {
      this.loading = true;
      let params = {
        ...this.params
      };
      params[this.key_word] = params.keyWord
      delete params.keyWord
      API_settingCustomer.getCustomerAccountIncome(params)
        .then(response => {
          this.loading = false;
          this.tableData = response;
        })
        .catch(() => (this.loading = false));
    },

    /**编辑、添加 */
    async handleEdit (row) {
      console.log(row);
      if (row) {//编辑
        this.detailedDialog.config.title = "编辑";
        //调用详情接口
        const response = await API_settingCustomer.getCustomerAccountIncomeDetail(row.id)
        this.goodsAuditForm = { ...response }
        this.goodsAuditForm.es_enterprise_collecting_account_id = this.parentId;
        this.isName = response.name;
        this.detailedDialog.display()
      } else {//添加
        this.detailedDialog.config.title = "添加";
        this.goodsAuditForm = {
          es_enterprise_collecting_account_id: this.parentId,
          name: '',
          classification: '',
          tax_rate: '13',
          type: '',
          unit: '',//单位
          price: '',//单价
          left_num: '',//可开数量
        }
        this.detailedDialog.display()
      }
    },
    /**删除 */
    handleRemove (row) {
      this.$confirm("确定要删除当前的明细吗？", "提示", {
        type: "warning",
      }).then(() => {
        API_settingCustomer.delCustomerAccountIncome(row.id).then(() => {
          this.GET_GoodsAuditList();
          this.GET_classification();
          this.$message.success("删除成功");
        });
      });
    },
    /**导入明细 */
    handleImport () {
      this.tooltipContent = '';
      this.importData = [];
      this.addCardPasswordDialog.display();
    },
    /**导出全部 */
    handleExport () {
      this.importLoading = true;
      let tHeaders = [
        "货物编号",
        "货物名称",
        "所属分类",
        "税率(%)",
        "型号",
        "单位",
        "单价",
        "可开数量"
      ];
      let filterVals = [
        "no",
        "name",
        "classification",
        "tax_rate",
        "type",
        "unit",
        "price",
        "left_num"
      ];
      let params = { ...this.params };
      params[this.key_word] = params.keyWord
      delete params.keyWord
      API_settingCustomer.getCustomerAccountIncomeExport(params).then(res => {
        let data = res
        handleDownload(data, tHeaders, filterVals, "货物进项明细表");
        this.importLoading = false;
      });
    },
    /**提交充值表单 */
    submitGoodsAuditForm () {
      let { promise, resolve, reject } = Promise.withResolvers();
      this.$refs.goodsAuditForm.validate((valid) => {
        if (valid) {
          let goodsAuditForm = {
            ...this.goodsAuditForm,
          }
          if (goodsAuditForm.id) {
            API_settingCustomer.editCustomerAccountIncome(goodsAuditForm).then((res) => {
              this.$message.success('编辑成功');
              this.GET_GoodsAuditList();
              this.GET_classification();
              resolve(true)
            });
          } else {
            API_settingCustomer.addCustomerAccountIncome(goodsAuditForm).then((res) => {
              this.$message.success('添加成功');
              this.GET_GoodsAuditList();
              this.GET_classification();
              resolve(true)
            });
          }
        } else {
          reject(false)
        }
      });
      return promise
    },
    /**历史记录 */
    handleHistory () {
      this.exportHistoryVisible = true;
      this.$refs.exportHistory.show(this.$route.params.id);
    },
    /**上传成功的回调 */
    excelSuccess ({ results }) {
      console.log(results);
      const names = Object.keys(results[0]);
      const isshows = names.every(as => as === '货物名称' || as === '货物分类' || as === '税率(%)' || as === '型号' || as === '单位' || as === '单价' || as === '可开数量')
      if (isshows == false) {
        return this.tooltipContent = '', this.$message.error("导入失败，因导入的文件格式不正确，请选择正确格式的文件")
      }
      if (results.length == 1 && Object.values(results[0]).every(items => items == '')) return this.tooltipContent = '', this.$message.error("导入失败，因导入的文件中没有相关数据")
      if (results.length > 10000) return this.tooltipContent = '', this.$message.error("导入失败，因导入文件中的数据量超过1万条，请进行删减后重试")
      const resMap = {
        '货物名称': 'name',
        '货物分类': 'classification',
        '税率(%)': 'tax_rate',
        '型号': 'type',
        '单位': 'unit',
        '单价': 'price',
        '可开数量': 'left_num'
      }
      const newList = results.map(res => {
        let obj = {};
        Object.entries(res).forEach(([label, value]) => {
          obj[resMap[label]] = res[label]
        })
        return obj

      })
      this.error_msg = false;
      this.importData = newList;
    },
    //删除已上传文件
    clearnUploadFiles () {
      this.importData = [];
      this.tooltipContent = '';

    },
    inputfilter (str, name) {
      const regex = /^[1-9]\d*$/;
      if (!regex.test(str)) {
        this.goodsAuditForm[name] = '';
      }
    },
    moneryfilter (str, name) {
      const regex = /^[1-9]\d*$/;
      if (!regex.test(str)) {
        this.goodsAuditForm[name] = '';
      }
    },
    /** 提交文件 */
    uploadData () {
      const { promise, resolve, reject } = Promise.withResolvers();
      if (this.importData == '') {
        this.error_msg = true;
        return Promise.reject(false)
      } else {
        this.error_msg = false;
      }
      this.cancelActivatedAllLoading = true;

      API_settingCustomer.getCustomerAccountIncomeImport(this.importData, this.parentId)
        .then(res => {
          console.log(res);
          this.postponeTipText = `本次表格导入数据共${this.importData.length}条，其中成功${res.okCount}条，失败${res.errorCount}条。`
          this.uploadErrData = res.data // 失败数据暂存
          this.postponeTip.display()
          this.GET_GoodsAuditList(); // 更新数据
          this.cancelActivatedAllLoading = false;
          resolve(true)
        })
        .catch(() => {
          reject(false)
          this.cancelActivatedAllLoading = false;
        });
    },
    /** 下载失败数据 */
    downloadErrData () {
      if (!this.uploadErrData.length) return this.$message.error('没有失败数据')
      const errData = []
      this.uploadErrData.forEach(item => {
        errData.push([item.name, item.classification, item.tax_rate, item.type, item.unit, item.price, item.left_num, item.import_error_message])
      })
      let filename = `错误数据_${Date.now()}`;
      couponExport_down_to_excel([
        "货物名称", '货物分类', '税率(%)', '型号', '单位', '单价', '可开数量', '失败原因'
      ], errData,
        filename)
      this.postponeTip.dismiss()// 关闭弹窗
    },
    /**下载导入模板 */
    downTemplate () {
      const integralImportData = [
        {
          name: '',
          classification: '',
          tax_rate: '',
          type: '',
          unit: '',
          price: '',
          left_num: '',
        }
      ]

      handleDownload(integralImportData, this.tHeaders, this.filterVals, "进项明细模板");
    }
  },


}
</script>
<style lang="scss" scoped>
.buttonbc {
  width: 100%;
  text-align: right;
}

.tip {
  font-size: 13px;

  p {
    margin: 0
  }
}

.tipContent-text {
  padding-bottom: 10px;
}

.tipContent-button {
  text-align: right;
}
</style>