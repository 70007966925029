<template>
  <x-dialog :proxy="couponsDialog">
    <en-table-layout :tableData="tableData.data">
      <template slot="toolbar">
        <el-form-item label="操作类型">
          <el-select style="width: 100px" v-model="params.type" size="small" placeholder="请选择" clearable>
            <el-option label="全部" value="" />
            <el-option label="导入" :value="0" />
            <el-option label="导出" :value="1" />
          </el-select>
        </el-form-item>
        <el-form-item label="操作时间" class="col-auto">
          <el-date-picker v-model="order_time_range" type="daterange" style="width: 250px" range-separator="-"
            start-placeholder="开始日期" end-placeholder="结束日期" @change="handleDateChange" />
        </el-form-item>
        <el-form-item>
          <el-input style="width: 280px;" size="small" placeholder="请输入操作人名称进行搜索" v-model.trim="params.operator_name"
            clearable>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button native-type="submit" @click.stop.prevent="getTableList(1)" size="small" type="primary">
            搜索
          </el-button>
        </el-form-item>
      </template>

      <template slot="table-columns">
        <el-table-column label="操作类型">
          <template slot-scope="scope"> {{ scope.row.type ? "导出" : "导入" }}</template>
        </el-table-column>
        <el-table-column label="操作时间" width="160">
          <template slot-scope="{row}">{{ row.create_time | unixToDate }}</template>
        </el-table-column>
        <el-table-column prop="operator_name" label="操作人" />
        <el-table-column prop="count" label="操作数据量" />
        <el-table-column label="操作">
          <template slot-scope="{row}">
            <el-button type="primary" size="small" @click="handleDownload(row)">下载文件</el-button>
          </template>
        </el-table-column>
      </template>
      <el-pagination slot="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="tableData.page_no" :page-sizes="MixinPageSizes" background :layout="MixinTableLayout"
        :page-size="tableData.page_size" :total="tableData.data_total" />
    </en-table-layout>
  </x-dialog>
</template>
<script>
import EnTableLayout from '@/../ui-components/TableLayout/src/main';
import XDialog from "@/components/x-dialog/x-dialog";
import { $xDialog } from "@/components/x-dialog/dialog.proxy";
import * as API_settingCustomer from '@/api/settingCustomer';
import { handleDownload } from "@/utils";
export default {
  name: 'settingcoupon',
  components: {
    XDialog,
    EnTableLayout
  },
  props: {
    parentId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      tHeaders: ["货物名称", "货物分类", "税率", "型号", "单位", "单价", "可开数量"],
      filterVals: ["name", "classification", "tax_rate", "type", "unit", "price", "left_num"],
      params: {
        operator_name: '',
        type: "",
        page_size: 20,
        page_no: 1,
        es_enterprise_collecting_account_id: ''
      },
      tableData: {
        data: []
      },
      order_time_range: [],
      couponsDialog: $xDialog.create({
        title: "导入/导出记录",
        width: "65vw",
        disableCancel: true,
        disableConfirm: true,
        wrapperClass: "cash-coupon-manager-main-dialog",
        afterDismiss: () => this.reset(),
      }),
    }
  },
  mounted () {
    this.getTableList();
  },
  methods: {
    show (row) {
      this.params = {
        operator_name: '',
        type: "",
        page_size: 20,
        page_no: 1,
        es_enterprise_collecting_account_id: ''
      }
      this.couponsDialog.display();
      this.params.es_enterprise_collecting_account_id = row
      this.$nextTick(() => {
        this.getTableList()
      })
    },
    /**下载文件 */
    handleDownload (row) {
      API_settingCustomer.getCustomerAccountIncomeImportExportInfo(row.id).then((res) => {
        let tHeaders = [
          ...this.tHeaders
        ]
        let filterVals = [
          ...this.filterVals
        ]
        console.log(row.type == 1);
        if (row.type == 1) {//添加货物编号
          tHeaders.unshift("货物编号")
          filterVals.unshift("no")
        }
        handleDownload(res, tHeaders, filterVals, "操作记录");
      })
    },
    //格式化时间控件
    handleDateChange () {
      if (
        this.order_time_range &&
        this.order_time_range.length
      ) {
        if (typeof this.order_time_range[0] === "string") {
          this.params.create_time_start =
            new Date(this.order_time_range[0]).getTime() / 1000;
          this.params.create_time_end =
            new Date(this.order_time_range[1]).getTime() / 1000 +
            86400;
        } else {
          this.params.create_time_start =
            this.order_time_range[0].getTime() / 1000;
          this.params.create_time_end =
            this.order_time_range[1].getTime() / 1000 + 86400;
        }
      } else {
        this.params.create_time_start = "";
        this.params.create_time_end = "";
      }
    },
    /**查询记录列表 */
    getTableList (page_no) {
      let params = this.params;
      page_no && (params.page_no = 1);
      if (this.parentId != '') {
        params.es_enterprise_collecting_account_id = this.parentId
      }
      API_settingCustomer.getCustomerAccountIncomeImportList(params).then((res) => {
        this.tableData = res;
      });
    },
    handleSizeChange (val) {
      this.params.page_size = val;
      this.getTableList();
    },
    handleCurrentChange (val) {
      this.params.page_no = val;
      this.getTableList();
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/ .el-scrollbar__bar.is-vertical {
  width: 0;
}
</style>